import { createSlice } from '@reduxjs/toolkit'
import Tool from '../utils/tool'

const initialState = {
  token: Tool.data.get('TOKEN'),
  userInfo: Tool.data.get('USERINFO'),
  weixin: Tool.data.get('WEIXIN')
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser(state, action) {
      Tool.data.set('TOKEN', action.payload.token)
      Tool.data.set('USERINFO', action.payload.userInfo)
      state.token = action.payload.token
      state.userInfo = action.payload.userInfo
    },
    setWeixin(state, action) {
      Tool.data.set('WEIXIN', action.payload)
      state.weixin = action.payload
    },
    clear(state, action){
      Tool.data.clear()
    }
  },
})

export const { setUser, setWeixin, clear } = userSlice.actions
export default userSlice.reducer
