import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import api from '@/api/index'
import tool from '@/utils/tool'
import { setUser, setWeixin, clear } from '@/store/user.slice'

function Weixin() {
  let code = tool.getQueryVariable('code')
  const dispatch = useDispatch()
  const navigateTo = useNavigate()

  useEffect(() => {
    dispatch(clear(null))
    getWxuserinfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getWxuserinfo = async () => {
    let re = await api.wxuser.get({ code: code })
    if (re.status === 1) {
      dispatch(setWeixin(re.data.weixin))

      if (re.data.token !== undefined) {
        dispatch(setUser(re.data))
        return navigateTo('/draw', { replace: true })
      }
      return navigateTo('/login', { replace: true })
    }
  }

  return (
    <div className="viewport viewport2">
      <div className="gpage4">
        <div>
          <img src="/images/png03.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Weixin
