import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function AuthActivity({ children }) {

  const userStore = useSelector((state) => state.user);

  // 活动 是否存在、是否开始、是否结束
  if (userStore.weixin !== null) {
    return <>{children}</>
  } else {
    return <Navigate to="/" replace></Navigate>
  }
}

export default AuthActivity
