import http from '@/utils/request'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: {
    url: `/login/index`,
    name: '登录',
    post: async function (data, config = {}) {
      return await http.post(this.url, data, config)
    },
  },
  smscode: {
    url: `/login/smscode`,
    name: '获取短信码',
    post: async function (data, config = {}) {
      return await http.post(this.url, data, config)
    },
  },
  draw: {
    url: `/user/draw`,
    name: '领取开门红包',
    post: async function (data) {
      return await http.post(this.url, data)
    },
  },
  result: {
    url: `/user/result`,
    name: '查询某个员工领取红包结果',
    get: async function (params) {
      return await http.get(this.url, params)
    },
  },
  activity: {
    url: `/user/activity`,
    name: '查询活动',
    get: async function (params) {
      return await http.get(this.url, params)
    },
  },
  wxuser: {
    url: `/user/wxuser`,
    name: '拉取微信用户信息',
    get: async function (params) {
      return await http.get(this.url, params)
    },
  }
}
