import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function AuthLogin({ children }) {

  const userStore = useSelector((state) => state.user);
  // token 是否存在
  if (userStore.token !== null) {
    return <>{children}</>
  } else {
    return <Navigate to="/login" replace></Navigate>
  }
}

export default AuthLogin
