import React, { lazy, Suspense } from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import AuthActivity from '@/components/AuthActivity'
import AuthLogin from '@/components/AuthLogin'
import Weixin from '@/pages/Weixin'

const Login = lazy(() => import('@/pages/Login'))
const Index = lazy(() => import('@/pages/Laoyout/index'))
const Draw = lazy(() => import('@/pages/Laoyout/draw'))

function App() {
  return (
    <HashRouter>
      <Suspense
        fallback={
          <div
            style={{
              textAlign: 'center',
              marginTop: 200,
            }}
          >
            Loading……
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/weixin" element={<Weixin />} />
          <Route
            path="/login"
            element={
              <AuthActivity>
                <Login />
              </AuthActivity>
            }
          />
          <Route
            path="/draw"
            element={
              <AuthLogin>
                <Draw />
              </AuthLogin>
            }
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
