const DEFAULT_CONFIG = {
    //标题
    APP_NAME: "百事新年红包",
  
    //接口地址
    //API_URL:'https://pepsicoredpacket.pepsico.com.cn/wapapi.php',
    //API_URL:'http://localhost:8000/wapapi.php',
    API_URL:'https://baishihongbao.stsv.cn/wapapi.php',

    //请求超时
    TIMEOUT: 3000,
  
    //企业微信
    APPID:'ww2486de93195afe0a',
    AGENTID: 1000002,
    APPSECRET: 'qhZXarNp_NHBf_1bEql6PukHbvTe26wLY15Kxrr37yU',
    //REDIRECT_URI:'https://pepsicoredpacket.pepsico.com.cn/weixin',
    REDIRECT_URI:'https://baishihongbao.stsv.cn/#/weixin/',
  
    //TokenName
    TOKEN_NAME: "Authorization",
  
    //Token前缀，注意最后有个空格，如不需要需设置空字符串
    TOKEN_PREFIX: "Bearer ",
  };
  
  export default DEFAULT_CONFIG;
  